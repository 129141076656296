import { Box, Grid, Link, Typography } from "@mui/material";
import AppPhoneNumber from "../../../components/AppPhoneNumber";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { Fonts } from "../../../constants/AppEnums";
import { fontColor } from "../../../constants/ColorSets";

const RuleEmergencyList: React.FC = () => {

    const getPhoneElement = (title: string, subTitle: string, phone: string) => {
        return (<AppPhoneNumber>
            <Box className="avatar-icon">
                <LocalPhoneOutlinedIcon />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ color: fontColor.titleFontColor, fontWeight: Fonts.SEMI_BOLD }}>{title}</Typography>
                <Typography sx={{ color: fontColor.subTitleFontColor, fontSize: 15 }}>{subTitle}</Typography>
                <Link color="inherit" underline="hover" href={`tel:${phone}`}>{phone}</Link>
            </Box>
        </AppPhoneNumber>);
    }
    return (
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} sx={{
            "& .emergency-title": {
                fontWeight: Fonts.BOLD,
                color: fontColor.titleFontColor
            }
        }}>
            <Grid item xs={4}>
                <Box>
                    <Box className="emergency-title">Contact</Box>
                    <AppPhoneNumber>
                        <Box className="avatar-icon">
                            <LocalPhoneOutlinedIcon />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography sx={{ color: "red", fontWeight: Fonts.SEMI_BOLD }}>Train Control</Typography>
                            <Typography sx={{ color: fontColor.subTitleFontColor }}>All Rail Emergencies</Typography>
                            <Link color="inherit" underline="hover" href={`tel:0800 808 400`} >0800 808 400</Link>
                        </Box>
                    </AppPhoneNumber>
                </Box>
            </Grid>
            <Grid item xs={8}>
                <Box>
                    <Box className="emergency-title">Emergency Procedures</Box>
                    <Box sx={{ ml: 1, "li": { textIndent: "-20px", ml: "20px" } }}>
                        <li>Call emergency numbers</li>
                        <li>Shut down all plant and equipment</li>
                        <li>All personnel on site to proceed immediately by the safest identifiable route to the safe place</li>
                        <Box sx={{ mt: 1, ml: 1 }}>Stay there so a head count can be done against the TS90. Do not return to site until Team Leader has given clearance.</Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ p: 5 }}>
                <Box className="emergency-title">Additional Contacts</Box>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1} sx={{ "& .MuiGrid-item": { width: "100%" } }}>
                    <Grid item sm={4}>{getPhoneElement("Traction Control - AEA & WEA", "Overhead Line Equipment Damage", "04 498 3347")}</Grid>
                    <Grid item sm={4}>{getPhoneElement("Traction Control - NIMTEA", "Overhead Line Equipment Damage", "06 351 6839")}</Grid>
                    <Grid item sm={4}>{getPhoneElement("Operations Support (155)", "Infrastructure damage", "0800 288 000")}</Grid>
                    <Grid item sm={4}>{getPhoneElement("Network Control Manager", "All areas except Auckland", "04 498 2066")}</Grid>
                    <Grid item sm={4}>{getPhoneElement("Network Control Manager", "Auckland", "09 363 7735")}</Grid>
                    <Grid item sm={4}>{getPhoneElement("Linehaul Service Manager", "Nationwide", "09 363 8229")}</Grid>
                    <Grid item sm={4}>{getPhoneElement("Emergency Services", "Police, Fire, Ambulance", "111")}</Grid>
                    <Grid item sm={4}>{getPhoneElement("CHEMCALL", "Dangerous Goods Emergency Advice Line", "0800 CHEMCALL (0800 243 622)")}</Grid>
                    <Grid item sm={4}>{getPhoneElement("EAP Services - Telus Health", " Post-incident support for KR Personnel", "0800 574 357")}</Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

export default RuleEmergencyList;