import React, { useEffect, useState } from "react";
import { Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { theme } from "../../themes/theme";
import ProgressPieChart from "../../components/TableItems/ProgressPieChart";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { RuleUnitState } from "../../types/models/RuleUnitState";
import { getAlertRuleInstancesForRoles } from "../../utils/RuleUtil";
import { useAlerts } from "../../hooks/useAlerts";
import { displayUserRole } from "../../utils/StringUtils";
import { RoleState } from "../../types/models/RoleState";
import MemberModal from "../your-teams/MemberModal.tsx";
import { MyTeamId } from "../your-teams";
import { TeamMemberProgress } from "../../types/models/TeamMember";
import { useTeams } from "../../hooks/useTeams";
import { TeamMembersState } from "../../types/models/TeamMembersState";

const UserTable: React.FC = () => {
    const { isLoading, refreshAlerts } = useAlerts(); // Call APIs to get alert rules
    const { filterAlertProgress } = useTeams();
    const currentTeam = {TeamId: MyTeamId, DisplayName: "", StartDate: "", EndDate: "", Members: []};
    const teamState = useSelector<AppState, TeamMembersState>((state) => state.teamMembers);
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const roleState = useSelector<AppState, RoleState>((state) => state.role);
    const myRoles = roleState.myRoles?.map((role) => role.RoleId);
    const myLocations = roleState.myLocations?.map((location) => location.LNIId);
    const alerts = ruleUnitState.baseAlertContentRevisionDetails?.map((baseAlert) => baseAlert.RuleInstances?.map((rule) => ({Metadata: rule.Metadata, RuleInstanceId: rule.RuleInstanceId}))).flat();

    const [openUserProgress, setOpenUserProgress] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<TeamMemberProgress | undefined>(teamState.userProgress);
    
    useEffect(() => {
        refreshAlerts();
        setSelectedUser(teamState.userProgress);
    }, [])

    const getPieChartForUserProgress = () => {
        // Get alerts filtered by roles in "access token". This does not include selected roles on filter modal
        const alerts = getAlertRuleInstancesForRoles(ruleUnitState.baseAlertRules ?? [], myRoles ?? [], myLocations ?? []);
        const histories = getAlertRuleInstancesForRoles(ruleUnitState.baseAlertHistories ?? [], myRoles ?? [], myLocations ?? []);
        const notAcknowledgedTotal = histories.length - alerts.length;
        return (
            <Box sx={{ position: "relative" }}>
                <ProgressPieChart total={alerts.length + histories.length} completed={histories.length} />
                {notAcknowledgedTotal > 0 && notAcknowledgedTotal < 10 ? 
                        <span className="not-acknowledged-small-number">{notAcknowledgedTotal}</span> 
                    : <span className="not-acknowledged-number">{notAcknowledgedTotal}</span> }
            </Box>
        );
    };

    const SkeletonRow = () => {
        return <TableRow >
            {Array.from({ length: 2 }, (_, i) => <TableCell key={i} height={"52px"}><Skeleton variant="text" height='1.5em' /></TableCell>)}
        </TableRow>
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
                <Table size="small">
                    <TableHead sx={{ backgroundColor: theme.table.subHeaderBgColor }}>
                        <TableRow>
                            <TableCell>Roles</TableCell>
                            <TableCell align="center" width={"120px"}>Alerts</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ?
                            (
                                <>
                                    <SkeletonRow />
                                    <SkeletonRow />
                                </>
                            ) : (
                                <TableRow sx={{ cursor: "pointer" }} onClick={() => setOpenUserProgress(true)}>
                                    <TableCell>{roleState.myRoles?.map(role => displayUserRole(role.RoleId, roleState.knownRoles?.roles)).join(", ")}</TableCell>
                                    <TableCell>{getPieChartForUserProgress()}</TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </TableContainer>
            {teamState.userProgress &&
                <MemberModal 
                    alerts={alerts}
                    currentTeam={currentTeam}
                    onClose={() => setOpenUserProgress(false)}
                    open={openUserProgress}
                    selectedMember={selectedUser}
                    setSelectedMember={setSelectedUser}
                    user={teamState.userProgress}
                    userProgress={filterAlertProgress(teamState.userProgress.progress ?? [], teamState.userProgress.roles ?? [], teamState.userProgress.locations ?? [])}
                />
            }
        </>
    );
};

export default UserTable;
