import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Acknowledgment } from "../../../types/models/Acknowledgement";
import { RuleInstance } from "../../../types/models/RuleInstance";
import { Fonts } from "../../../constants/AppEnums";
import { theme } from '../../../themes/theme';
import { fontColor } from "../../../constants/ColorSets";
import { getMetaData, isOverdue } from "../../../utils/RuleUtil";
import { AlertMetaDataInstance } from "../../../types/models/AlertMetaData";

interface MemberAlertProp {
    progress: Acknowledgment;
    ruleInstance?: RuleInstance;
    openAlertChat: (ruleInstanceId: string) => void;
    alerts: AlertMetaDataInstance;
    getUnreadAlert: (alert: Acknowledgment) => boolean | undefined | null;
}

const MemberAlert: React.FC<MemberAlertProp> = ({ progress, ruleInstance, openAlertChat, alerts, getUnreadAlert }) => {

    const getOverdueAlert = (progress: Acknowledgment) => {
        if(!progress) {
            return null;
        }

        const metaData = getMetaData(alerts, progress.RuleInstanceId);
        
        if(metaData) {
            if(isOverdue(metaData)) {
                return true;
            }
        }

        return false;
    }

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                mt: "-1px",
                border: "1px solid lightgray",
                backgroundColor: theme.table.subHeaderBgColor,
                borderRadius: 0
            }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {progress.Resolved ? (
                    <CheckCircleOutlineOutlinedIcon
                        sx={{ color: theme.component.alertResolvedColor, fontSize: "20px" }}
                    />
                ) : getOverdueAlert(progress) ? (
                    <WarningAmberRoundedIcon
                        sx={{ color: theme.component.alertOverdueColor, fontSize: "20px" }}
                    />
                ) : <CancelOutlinedIcon
                        sx={{ color: theme.component.alertUnresolvedColor, fontSize: "20px" }}
            />}
                <Typography
                    sx={{
                        marginLeft: "5px",
                        fontWeight: Fonts.REGULAR,
                        fontSize: 14,
                    }}
                >
                    {ruleInstance?.DisplayName}
                </Typography>
            </Box>
            {(progress.DisplayMessageCount > 0) && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        color: theme.palette.primary.main,
                        fontSize: "14px",
                        cursor: "pointer"
                    }}
                    onClick={() => openAlertChat(progress.RuleInstanceId)}
                >
                    {getUnreadAlert(progress) ? <Box sx={{mr: 1, mt: 0.5, width: "10px", height: "10px", borderRadius: "50px", background: fontColor.orangeTitle}}></Box> : null}
                    <ChatBubbleOutlineIcon fontSize="small" sx={{ mt: 0.5 }} />
                    {`(${progress.DisplayMessageCount > 0 ? progress.DisplayMessageCount : null}) >`}
                </Box>
            )}
        </Box>
    );
};

export default MemberAlert;