/* eslint-disable no-case-declarations */
import { getAuthUserInfoFromToken } from "../../components/AppBase/AppAuthProvider";
import { GET_ORG_TEAMS, GET_TEAM_MEMBERS, GET_USERS, TeamMembersActions, SEARCH_ORG_TEAMS, GET_MY_TEAMS, GET_PROGRESS_OF_TEAM } from "../../types/actions/TeamMembers.actions";
import { TeamMembersState } from "../../types/models/TeamMembersState";

const userInfo = getAuthUserInfoFromToken();

const initialSettings: TeamMembersState = {
  orgProgress: undefined,
  baseOrgTeams: undefined,
  orgTeams: undefined,
  orgTeamSearchText: undefined,
  users: undefined,
  myTeams: undefined,
  teamProgressList: undefined,
  userProgress: undefined,
};

const TeamMemberReducer = (state = initialSettings, action: TeamMembersActions) => {
  switch (action.type) {
    case GET_TEAM_MEMBERS:
      return {
        ...state,
        orgProgress: action.payload,
        userProgress: action.payload?.find((prog) => prog.email === userInfo?.Email),
      };
    case GET_ORG_TEAMS:
      return {
        ...state,
        baseOrgTeams: action.payload,
        orgTeams: action.payload,
        orgTeamSearchText: undefined,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SEARCH_ORG_TEAMS:
      return {
        ...state,
        orgTeams: action.payload.SearchResults,
        orgTeamSearchText: action.payload.SearchText
      };
    case GET_MY_TEAMS:
      return {
        ...state,
        myTeams: action.payload,
      };
    case GET_PROGRESS_OF_TEAM:
      const teamList = state.teamProgressList ?? [];
      const index = teamList.findIndex(team => team.TeamId === action.payload.TeamId)
      if (index >= 0) {
        teamList[index] = action.payload;
      } else {
        teamList.push(action.payload);
      }

      return {
        ...state,
        teamProgressList: teamList,
      };
    default:
      return state;
  }
};

export default TeamMemberReducer;
